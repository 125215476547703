import React from "react";
import classNames from "classnames";

interface Props {
    title: string;
    text?: string;
    icon?: React.ReactNode;
    inverse?: boolean;
}

const Stats: React.FC<Props> = ({ title, text, icon, inverse = false }) => {
    return (
        <div className={"flex flex-col justify-center space-y-0.5"}>
            <span
                className={classNames("text-md text-center font-semibold", {
                    "text-primary": !inverse,
                    "text-white": inverse,
                })}
            >
                {title}
            </span>

            <span
                className={classNames(
                    "mx-auto inline-flex items-center space-x-2 text-4xl font-extrabold ",
                    {
                        "text-primary": !inverse,
                        "text-white": inverse,
                    },
                )}
            >
                <span>{text}</span>
                {icon}
            </span>
        </div>
    );
};

export default Stats;
