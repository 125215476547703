import classNames from "classnames";
import React, { ButtonHTMLAttributes } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    design:
        | "Primary"
        | "Secondary"
        | "PrimaryTransparent"
        | "SecondaryTransparent"
        | "Danger"
        | "Success";
    loading?: boolean;
}

const Button: React.FC<Props> = ({
    loading,
    design,
    children,
    ...restOfProps
}: Props) => {
    const baseClass =
        "inline-flex items-center justify-center px-4 py-2 rounded-xl font-semibold text-xs uppercase text-white transition ease-in-out duration-300";

    const designClass = () => {
        if (design == "Primary")
            return "bg-primary hover:bg-primary-700 active:bg-primary-900 border-transparent focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-opacity-50";

        if (design == "Secondary")
            return "bg-white dark:bg-black-300 dark:hover:bg-black-100 hover:bg-gray-200 active:bg-gray-400 text-primary border-transparent focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50";

        if (design == "PrimaryTransparent")
            return "border-2 border-primary text-primary hover:bg-primary hover:text-white active:bg-primary-900 border-transparent focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-opacity-50";

        if (design == "SecondaryTransparent")
            return "border-2 border-white hover:bg-white hover:text-primary active:bg-gray-400 border-transparent focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50";

        if (design == "Danger")
            return "bg-red-500 hover:bg-red-700 active:bg-red-900 border-transparent focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50";

        if (design == "Success")
            return "bg-green-500 hover:bg-green-700 active:bg-green-900 border-transparent focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50";
    };

    const classes = classNames(baseClass, designClass());

    return (
        <button disabled={loading} className={classes} {...restOfProps}>
            {children}
        </button>
    );
};

export default Button;
