import Image from "next/image";
import { NextPageWithCustomLayout } from "./_app";
import Layout from "../components/Layout";
import ContainerBuilder from "../components/ContainerBuilder";
import Button from "../components/Button";
import Link from "next/link";
import Stats from "../components/Stats";
import React from "react";
import Hero from "../components/Hero";
import CustomLink from "../components/CustomLink";

import heroImage from "../public/img/index/1.png";
import drivershubImage from "../public/img/index/1-2.png";
import drivershubBackground from "../public/img/index/1-4.png";
import deBackground from "../public/img/index/1-5.png";
import applyBackground from "../public/img/index/1-6.png";
import awardIcon from "../public/img/index/award.png";
import discord from "../public/img/index/discord.png";
import divisions from "../public/img/index/divisions.png";
import peopleIcon from "../public/img/index/people.png";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Head from "next/head";

const Home: NextPageWithCustomLayout = () => {
    return (
        <div className="flex flex-col space-y-4">
            <Head>
                <meta
                    key="twitter:title"
                    name="twitter:title"
                    content="Viva Trucking - Trucksim's Leading VTC Since 2016"
                />
                <meta
                    key="og:title"
                    property="og:title"
                    content="Viva Trucking - Trucksim's Leading VTC Since 2016"
                />
                <title>Viva Trucking - Trucksims Leading VTC Since 2016</title>
            </Head>

            <ContainerBuilder
                left={
                    <div className={"flex flex-col justify-center space-y-4"}>
                        <span className={"title text-primary"}>About Us</span>
                        <p>
                            Viva Trucking is an ATS and ETS2 trucking company
                            like no other. We are a community dedicated to
                            changing the way virtual trucking is today. We hire
                            truckers from all over the world who are committed
                            to being valuable members of our innovative company.
                            Our mission has always been to build a place where
                            truckers from all backgrounds can come together to
                            form a friendly community. Our drivers always come
                            first, making responsibility, respect, and trust,
                            the basis upon which we have built our thriving
                            community.
                        </p>
                        <span className={"inline-flex justify-center"}>
                            <Link href={"/about"}>
                                <Button design={"Primary"}>About Us</Button>
                            </Link>
                        </span>
                    </div>
                }
                right={
                    <div className={"w-full p-4"}>
                        <iframe
                            className="... aspect-video w-full"
                            src="https://www.youtube.com/embed/CRxsEwXlp9s"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                }
                orientation={"ltr"}
            />

            <ContainerBuilder
                left={
                    <div
                        className={
                            "mx-auto flex items-center justify-center py-5"
                        }
                    >
                        <Image src={drivershubImage} alt={"DriversHub"} />
                    </div>
                }
                right={
                    <div
                        className={
                            "flex flex-col justify-end space-y-4 py-5 text-right"
                        }
                    >
                        <span className={"title text-white"}>
                            Our Technology
                        </span>
                        <p className={"text-white"}>
                            The Drivers Hub is the heart of Vivas operations.
                            This custom-built system tracks drivers jobs and
                            company data which are used to rank drivers within
                            the VTC. Social features such as Divisions, Driver
                            Spotlights, and much more help bring our community
                            together. Live stats display job history and driving
                            performance to our drivers accounts. All of this is
                            introduced in our online training interface, which
                            gets our new members up to speed on everything they
                            will need to know about Viva. Our Drivers Hub has
                            cost thousands of dollars to make and is constantly
                            being updated to provide our community with exciting
                            new features.
                        </p>
                    </div>
                }
                background={drivershubBackground}
                orientation={"rtl"}
            />

            <ContainerBuilder>
                <div className="flex flex-wrap justify-center sm:space-x-24">
                    <Stats
                        title={"Total Active Drivers"}
                        icon={
                            <>
                                <Image
                                    width={50}
                                    height={32}
                                    src={peopleIcon}
                                    alt={"People Icon"}
                                />
                            </>
                        }
                        text={"1,500+"}
                    />
                    <Stats
                        title={"Discord Partner"}
                        icon={
                            <>
                                <Image
                                    width={200}
                                    height={45}
                                    src={discord}
                                    alt={"Discord Logo"}
                                />
                            </>
                        }
                    />
                    <Stats
                        title={"Years of Operation"}
                        icon={
                            <>
                                <Image
                                    width={30}
                                    height={42}
                                    src={awardIcon}
                                    alt={"Award Icon"}
                                />
                            </>
                        }
                        text={"6 Years"}
                    />
                </div>
            </ContainerBuilder>

            <ContainerBuilder background={deBackground}>
                <div
                    className={
                        "mx-auto flex max-w-2xl flex-col justify-center space-y-4 py-5 text-center"
                    }
                >
                    <span className={"title text-white"}>
                        Our Driver Experience
                    </span>

                    <p className={"text-white"}>
                        Viva Trucking focuses on our drivers more than anything.
                        Our award-winning staff dedicates their time to make
                        every drivers experience one to remember. Without our
                        drivers, there would be no Viva Trucking. Click the
                        button below to read how Viva has impacted dozens of
                        lives.
                    </p>

                    <span className={"inline-flex justify-center"}>
                        <Link href={"/about"}>
                            <Button design={"SecondaryTransparent"}>
                                Explore
                            </Button>
                        </Link>
                    </span>
                </div>
            </ContainerBuilder>

            <ContainerBuilder
                left={
                    <div className={"flex flex-col justify-center space-y-4"}>
                        <span className={"title text-primary"}>Divisions</span>
                        <p>
                            Specialty Divisions are an original Viva Trucking
                            feature that allows our community to enhance their
                            gameplay experience in exciting new ways. Our
                            drivers can join Agriculture, Flatbed, Hazmat, Heavy
                            Haul, Intermodal, and Reefer Divisions to gain more
                            revenue through division-dependent bonuses and add
                            extra realism to their gameplay experience through
                            advanced job logging features. Learn more about all
                            the divisions Viva has to offer on our Division
                            Page.
                        </p>
                        <span className={"inline-flex justify-center"}>
                            <Link href={"/about"}>
                                <Button design={"Primary"}>Learn More</Button>
                            </Link>
                        </span>
                    </div>
                }
                right={
                    <div className={"mx-auto flex items-center justify-center"}>
                        <Image src={divisions} alt={"Divsion Trucks"} />
                    </div>
                }
                orientation={"rtl"}
            />

            <ContainerBuilder background={applyBackground}>
                <div
                    className={
                        "mx-auto flex max-w-2xl flex-col justify-center space-y-4 py-5 text-center"
                    }
                >
                    <span className={"title text-white"}>Join Us Today</span>

                    <p className={"text-white"}>
                        With the click of a button, you will be able to
                        transform your truck sim experience into something
                        worthwhile. Click the button below to join the Kings of
                        the Road!
                    </p>

                    <span className={"inline-flex justify-center"}>
                        <Link href={"/apply"}>
                            <Button design={"SecondaryTransparent"}>
                                Apply Now
                            </Button>
                        </Link>
                    </span>
                </div>
            </ContainerBuilder>
        </div>
    );
};

Home.getLayout = (page) => {
    return (
        <Layout
            hero={
                <Hero hero={heroImage} overlay={false}>
                    <Navbar />
                    <div className="container mx-auto flex min-h-screen items-center px-4 sm:px-0">
                        <div className="flex flex-col space-y-4 text-white">
                            <span className={"text-6xl font-extrabold"}>
                                Kings Of
                            </span>
                            <span className={"text-6xl font-extrabold"}>
                                The Road
                            </span>

                            <p>Trucksim&apos;s Leading VTC Since 2016</p>

                            <span className={"inline-flex"}>
                                <CustomLink href={"/about"}>
                                    <Button design={"SecondaryTransparent"}>
                                        Learn More
                                    </Button>
                                </CustomLink>
                            </span>
                        </div>
                    </div>
                </Hero>
            }
        >
            {page}
            <Footer />
        </Layout>
    );
};

export default Home;
